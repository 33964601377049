a.link {
    color: white !important;
}

button.link {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
}

.flycatcher-modal {
    background-color: var(--recipBlue);
    border-radius: 5px;
    color: white;
    width: 75% !important;
    margin-left: 12.5%;
    margin-left: 12.5%;
    margin-top: 2%;
    height: 92% !important;
    opacity: 1;
}

.flycatcher-form {
    margin-top: 3%;
    margin-bottom: 3%;
}

button.visit-site {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
}

button.sign-up {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    font-size: 1.5rem;
    border-radius: 5px;
}

button.sign-up:hover:enabled {
    background-color: var(--recipGreen);
    color: white;
    border: 2px solid white;
    font-size: 1.5rem;
    transition: 0.3s linear;
}
button.sign-up:disabled {
    opacity: 0.5;
}

a.link {
    color: white !important;
}

button.link {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
}

.flycatcher-img {
    width: 50%;
    height: auto;
}

.flycatcher-dialog{
    background-color: transparent !important;
    border: none !important;
    margin-top: 2% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    max-width: 80% !important;
    width: 100%  !important;
    height: 90% !important;
  }
  
  .flycatcher-content{
    background-color: transparent !important;
    border: none !important;
    color:white !important;
    margin: 0% !important;
    height:100% !important;
    width:100% !important;
  }
