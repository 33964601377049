.body {
  padding-top: 138px;
}

@media (max-width: 992px) {
  .body {
      padding-top: 90px;
  }
}

@media (max-width: 387px) {
  .body {
      padding-top: 130px;
  }
}