.titleContainer {
  display: flex;
  justify-content: center;
  height: 800px;
  width: 100%;
  position: relative;
}

.titleImage {
  margin-bottom: 40px;
  max-width: 100%;
  min-width: none;
  position: absolute;
  bottom: 0;
}

@media (max-width: 1380px) {
  .titleContainer {
    height: auto;
  }
  .titleImage {
    max-width: 100vw;
    min-width: 100%;
    position: relative;
    bottom: none;
  }
}
