.paymentSelectButton {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
}

.customCardSelected {
  border-radius: 15px;
  border: 2px solid #93ba21;
  box-shadow: 5px;
  transition: box-shadow 0.2s;
}

.customCardSelected h1 {
  color: #93ba21;
}

.customCardSelected:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
  cursor: pointer;
}

.customCard {
  opacity: 0.5;
  color: #a6a6a6;
  border-radius: 15px;
  border: 2px solid #a6a6a6;
  box-shadow: 5px;
  transition: box-shadow 0.2s;
}

.customCard h1 {
  color: #a6a6a6;
}

.customCard:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
  cursor: pointer;
}

.recommended {
  font-size: 1.25em;
  font-weight: 400;
}

.address {
  font-size: 1em;
  font-weight: 400;
}
