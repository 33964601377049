.container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

h1{
  font-size: 4rem;
  font-weight: 600;
}

.inner {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.dashboardButton {
  background: #93BA21 !important;
  color: white !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.badge {
  margin-top: 5rem;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .spacing {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .stayInTouchRow {
    height: 408px;
  }

  h1{
    font-size: 2.7rem !important;
  }
}
