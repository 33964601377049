.thumbnail {
  border: 0px !important;
  height: 250px;
  width: 200px;
}

.signUp {
  margin-bottom: 2rem;
  font-size: 0.875rem;
}

.rural {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
}

.ruralInfo {
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.ruralInfo > button {
  font-size: 0.875rem;
}

.info {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
}

.info > button {
  font-size: 0.875rem;
}

.requiredTag {
  text-align: right;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .rural {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .info {
    margin-bottom: 0rem;
  }
}
