.stayInTouchRow {
  background-color: #000000;
  color: white;
  text-align: center;
  height: 375px;
}

.formContainer {
  max-width: 500px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.formRow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.customBtn {
  width: 100%;
  background: #93BA21;
  color: white;
  border: 2px solid #93BA21;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}
.customBtn:hover {
  background: black;
  color: #93BA21;
  border: 2px solid #93BA21;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.spacing {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .spacing {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .stayInTouchRow {
    height: 408px;
  }
}
