.buttonRow {
  margin-bottom: 3rem;
}

.totalCard {
  margin-bottom: 2rem;
  width: 100%;
}

.publicMap {
  margin-bottom: 2rem;
  text-align: center;
}

.hr {
  border-color: black;
}

.creditCardForm {
  margin-bottom: 1rem;
  width: 100%;
}
