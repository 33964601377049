.nav a {
  color: black !important;
  border: 4px solid transparent;
}

.nav a:active{
  background-color: #b9b9b9 !important;
}

.navbar {
 padding: 2rem 4rem !important;
}

.navbar img {
  height: 4rem;
}

.navMenu {
  font-size: 1.25rem !important;
  margin-right: 1.5rem;
  white-space: nowrap;
}

@media (max-width: 992px) {
  .navbar {
    padding: 1rem 2.5rem !important;
  }

  .navbar img {
    height: 3rem;
  }
}
