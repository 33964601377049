@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

html {
  --recipBlue: #6ec1e4;
  --recipGreen: #93ba21;
  --recipPurple: #6d5b9e;
  --recipPink: #eb638d;
  --recipRed: #de5246;
  --recipMaroon: #9f3a5a;
}

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h1 {
  font-size: 65px;
}

h3 {
  font-size: 20px;
}

.font-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
}

.reciprocityLightBlueText {
  color: var(--recipBlue);
}
.reciprocityLightBlueBG {
  color: white;
  background-color: var(--recipBlue);
}

.reciprocityGreenText {
  color: var(--recipGreen);
}

.reciprocityPurpleText {
  color: var(--recipPurple);
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.fa-facebook-square {
  color: var(--recipBlue);
}

.fa-twitter-square {
  color: var(--recipBlue);
}

.fa-linkedin-square {
  color: #0072b1;
}

.footer-social-media {
  color: var(--recipBlue);
  font-size: 50px !important;
}

.fa-envelope-square {
  color: var(--recipRed);
}

a {
  color: var(--recipGreen);
}

a:visited {
  color: var(--recipGreen);
}

a:hover {
  color: black;
}

.btn-link {
  color: var(--recipGreen);
}

.btn-link:visited {
  color: var(--recipGreen);
}

.btn-link:hover {
  color: black;
}

.btn[type="submit"] {
  width: 100%;
  text-transform: uppercase;
  background: var(--recipGreen) !important;
  color: white !important;
  border: 2px solid var(--recipGreen) !important;
}

.btn[type="submit"]:hover {
  background: white !important;
  color: var(--recipGreen) !important;
  border: 2px solid var(--recipGreen) !important;
}

.marginBottom25 {
  margin-bottom: 25px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.marginTop100 {
  margin-top: 100px;
}

.marginTop50 {
  margin-top: 50px;
}

.marginBottom100 {
  margin-bottom: 100px;
}

.marginBottom150 {
  margin-bottom: 150px;
}

.smallContainer {
  padding-left: 20%;
  padding-right: 20%;

  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 1199px) {
  .smallContainer {
    padding-left: 10%;
    padding-right: 10%;

    margin-top: 100px;
    margin-bottom: 100px;
  }
}

p {
  line-height: 2;
}

.pointer {
  cursor: pointer;
}

.customBtnGreen {
  width: 100%;
  background: var(--recipGreen) !important;
  color: white;
  border: 2px solid var(--recipGreen) !important;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}
.customBtnGreen:hover {
  background: black;
  color: var(--recipGreen);
  border: 2px solid var(--recipGreen);
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.customBtnBlue {
  width: 100%;
  background: var(--recipBlue);
  color: white;
  border: 2px solid var(--recipBlue);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}

.customBtnRenter {
  width: 100%;
  background: var(--recipPink);
  color: white;
  border: 2px solid var(--recipPink);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}

.customBtnBusiness {
  width: 100%;
  background: var(--recipPurple);
  color: white;
  border: 2px solid var(--recipPurple);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}

.customBtnElse {
  width: 100%;
  background: var(--recipMaroon);
  color: white;
  border: 2px solid var(--recipMaroon);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}

.customBtnIndigenous {
  width: 100%;
  background: var(--recipGreen);
  color: white;
  border: 2px solid var(--recipGreen);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
  transition: 0.3s;
}

.customBtnBlue:hover {
  background: white;
  color: var(--recipBlue);
  border: 2px solid var(--recipBlue);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.customBtnRenter:hover {
  background: white;
  color: var(--recipPink);
  border: 2px solid var(--recipPink);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.customBtnElse:hover {
  background: white;
  color: var(--recipMaroon);
  border: 2px solid var(--recipMaroon);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.customBtnBusiness:hover {
  background: white;
  color: var(--recipPurple);
  border: 2px solid var(--recipPurple);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.customBtnIndigenous:hover {
  background: white;
  color: var(--recipGreen);
  border: 2px solid var(--recipGreen);
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.disabledBtn {
  width: 100%;
  background: grey !important;
  color: white;
  border: 2px solid grey !important;
  border-radius: 4px;
  padding: 10px 10px;
  text-transform: uppercase;
}

.dropdown-item:hover {
  background-color: var(--recipBlue);
}

.btn-indigenous {
  color: white !important;
  font-size: 1.5rem;
}

.btn-indigenous:hover {
  color: var( --recipBlue) !important;
}

.btn-home{
  background-color: transparent;
  border-color: white;
  color: white;
  border-radius: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

a.btn-home{
  color: white;
}

.btn-home:hover {
  border-color: var(--recipBlue);
  background-color: transparent;
  color: var(--recipBlue);
  outline: solid 5px var(--recipBlue);
  animation: pulse 0.2s linear;
  -webkit-animation: pulse 0.2s linear;
  -moz-animation: pulse 0.2s linear;
  -o-animation: pulse 0.2s linear;
  -ms-animation: pulse 0.2s linear;
}

@keyframes pulse {
  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.accordion-button {
  background-color: var(--recipBlue) !important;
  color: white !important;
  border: transparent;

}

.faq-card {
  border: 10px solid white;
}

.banner {
  background-color: var(--recipBlue) !important;
  color: white !important;
}

.dropdown-menu {
  border-color: white !important;
}

.carouselText {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 50px;
}

.mapContainer{
    height: 30rem;
    margin-bottom: 2rem;
    flex: 1 1 auto;
}

@media (max-width: 400px) {
  .carouselText  {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -138px;
  visibility: hidden;
}

