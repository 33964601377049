.buttonRow {
  margin-bottom: 3rem;
}

.wantMoreCard {
  background-color: #ebebeb !important;
  display: flex;
  height: 100%;
  
}

.annualCard {
  background-color: #8dbe3f !important;
  color: white;
  margin-top: 1rem;
  font-size: .875rem;
}

.totalCard {
  height: 100%;
}

.publicMap {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.hr {
  border-color: black;
}

.subscriptionRow {
  margin-top: 1rem;
}

.creditCardForm {
  margin-bottom: 1rem;
}

