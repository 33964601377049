.requiredTag {
  text-align: right;
  margin-bottom: 1rem;
}

.hr {
  border-color: #ced4d1;
}

.noSession {
  margin-bottom: 1rem;
}
