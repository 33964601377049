.pinsContainer img {
  width: 100px;
  margin-bottom: 1rem;
}

.flexer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.mapContainer {
  height: 40rem;
  margin-bottom: 2rem;
  flex: 1 1 auto;
}

@media (max-width: 600px){
  .mapContainer {
    height: 20rem;
  }
}

.markerPopup > div {
  text-align: center;
}

.markerPopup span {
  font-size: 1rem;
}

.block {
  height: 5rem;
  margin-bottom: 1rem;
  opacity: 0.5;
}
