.mapRow {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.formContainer {
  max-width: 25%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.cardImage {
  display: block;
  width: 100%;
  min-height: 1035px;
  height: fit-content;
  object-fit: cover;
  position: relative;
  opacity: 0.4;
  border-radius: 0px !important;
  border-width: 0px !important;
}

.cardContainer {
  font-size: 1.5rem;
  line-height: 3rem;
}

.startBtnContainer {
  display: flex;
  margin-bottom: 100px;
}

.startBtnText {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  position:relative;
  top: 50%;
}
.startBtn:not(:hover):not(:active) {
  color: #f8f9fa !important;
}

.cardContent {
  display: flex;
  align-items: top;
  height: 35%;
  margin-bottom: 50px;
}

.cardContent h1 {
  font-size: 3.1rem !important;
  margin-top: 50px;
}

.card {
  background-color: white !important;
  max-height: 860px;
  overflow: hidden;
  padding: none !important;
  border-radius: 0px!important;
  border-width: 0px!important;
}

.cardBody {
  padding: none !important;
}

.animatedHeadline {
  margin-top: 0px;
  min-height: 100px;
}

.jumbotron {
  background-image: url('../../../public/home-background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, .6);
  background-blend-mode: darken;
  margin: none !important;
}

h1 .jumbotron {
  margin: none !important;
}

h1 {
  font-weight: 1000;
}

@media (max-width: 992px) {
  .mapRow {
    text-align: center;
  }
  .homeMapText {
    margin-bottom: 20px;
  }
  .card {
    max-height: fit-content!important;
  }
  .startBtnText {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
}

@media (max-width: 400px) {
  .animatedHeadline {
    min-height: 303px;
  }

  .cardContent h1 {
    font-size: 2rem !important;
  }
}

@media (max-width: 600px) {
  .cardImage {
    min-height: 1250px;
  }
}

@media (max-width: 357px) {
  .cardImage {
    min-height: 1400px;
  }
}